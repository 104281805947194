import React, { useRef, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import axios from "axios";
import { FlashMessage } from "../../flashmessage/FlashMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddVendorModal } from "../add-vendor-modal";
import Select from "react-select";
import './AddRequirementModel.css';

export const AddRequirementModal = (props) => {
  const [email, setEmail] = useState("");
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const jsonData = useSelector((state) => state.user);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [vendors, setVendors] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const employer = ["Technman Consulting"];
  const [modalShow, setModalShow] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  const [showNextSelect, setShowNextSelect] = useState(false);
  const [selectedConsultantId, setSelectedConsultantId] = useState(null);
  const [consultantProfileList, setConsultantProfileList] = useState([]);
  const [selectedConsultantProfileId, setSelectedConsultantProfileId] = useState(null);
  const [rate, setRate] = useState("");
  const [client, setClient] = useState("");
  const [selectedEmployer, SetSelectedEmployer] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [file, setFile] = useState(null);
  const [docs, setdocs] = useState("");
  const [comments, setcomments] = useState("");
  const [submit, setsubmit] = useState(false);
  const [proofOfSubmission, setProofOfSubmission] = useState(null);
  const isDocumentShared = useRef(0);
  const [marketingEmails, setMarketingEmails] = useState([]);
  const [marketingPhones, setMarketingPhones] = useState([]);

  useEffect(() => {
    setIsMounted(true);
    // Fetch marketing emails and phones from local storage
    const storedMarketingEmails = JSON.parse(localStorage.getItem('marketing_email')) || [];
    const storedMarketingPhones = JSON.parse(localStorage.getItem('marketing_mobile')) || [];
    setMarketingEmails(storedMarketingEmails);
    setMarketingPhones(storedMarketingPhones);
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants =
        `${process.env.REACT_APP_API_URL}/candidate/candidate/`;
      axios
        .get(apiUrlConsultants, {
          headers: {
            Authorization: `Token ${jsonData?.result.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setConsultantList(response.data.results);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [jsonData, isMounted]);

  useEffect(() => {
    if (isMounted && !modalShow && props.lead.company_id && jsonData) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/vendor/${props.lead.company_id}`;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Token ${jsonData?.result.token}`,
          },
        })
        .then((response) => {
          setVendors(response.data.results);
        })
        .catch((error) => console.error("Error fetching company data:", error));
    }
  }, [jsonData, isMounted, modalShow, props.lead.company_id]);

  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 4000);
  };

  const handleConsultantChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedConsultantId(selectedValue);
    if (!selectedValue) {
      setShowNextSelect(false);
      setSelectedConsultantProfileId(null);
      setConsultantProfileList([]);
    } else {
      const apiUrlConsultants =
        `${process.env.REACT_APP_API_URL}/candidate/candidate_profile/`;
      axios
        .get(apiUrlConsultants, {
          params: {
            consultant_id: selectedValue,
          },
          headers: {
            Authorization: `Token ${jsonData?.result.token}`,
          },
        })
        .then((response) => {
          setConsultantProfileList(response.data.results);
          setShowNextSelect(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  const handleConsultantProfileChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedConsultantProfileId(selectedValue);
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    if (!isValidEmail(enteredEmail)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (e) => {
    const enteredPhoneNumber = e.target.value;
    setPhoneNumber(enteredPhoneNumber);
    if (!isValidPhoneNumber(enteredPhoneNumber)) {
      setPhoneError("Invalid phone number.");
    } else {
      setPhoneError("");
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleVendorChange = (selectedOption) => {
    setSelectedVendorId(selectedOption?.id);
  };

  const handleRateChange = (e) => {
    const selectedValue = e.target.value;
    setRate(selectedValue);
  };

  const handleClientChange = (e) => {
    const selectedValue = e.target.value;
    setClient(selectedValue);
  };
  const handleDocsChange = (e) => {
    const selectedValue = e.target.value;
    setdocs(selectedValue);
  };
  const handleCommentssChange = (e) => {
    const selectedValue = e.target.value;
    setcomments(selectedValue);
  };

  const handleEmployerChange = (e) => {
    const selectedValue = e.target.value;
    SetSelectedEmployer(selectedValue);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const handleProofOfSubmissionChange = (event) => {
    const selectedFile = event.target.files[0];
    setProofOfSubmission(selectedFile);
  };

  const handleSubmit = () => {
    if (!submit) {
      setsubmit(true);
      console.log("-------------->", isDocumentShared.current.checked);
      const apiUrl = `${process.env.REACT_APP_API_URL}/marketing/submission/`;
      const formData = new FormData();
      formData.append('vendor_company', props.lead.company_id);
      formData.append('vendor_contact', selectedVendorId);
      formData.append('lead', props.lead.id);

      const trimmedRate = rate.trim();
      const rateValue = trimmedRate !== '' && !isNaN(parseFloat(trimmedRate))
        ? parseFloat(trimmedRate)
        : 0;
      formData.append('rate', rateValue);
      formData.append('client', client);
      formData.append('employer', selectedEmployer);
      formData.append('consultant', selectedConsultantProfileId);
      formData.append('marketing_email', email);
      formData.append('marketing_phone', phoneNumber);
      formData.append('documents_shared', docs);
      formData.append('comments', comments);
      formData.append('file_resume', file);
      formData.append('file_other', proofOfSubmission);

      if (isDocumentShared.current.checked == false)
        formData.append("document_shared", 0);
      else
        formData.append("document_shared", 1);
      axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${jsonData?.result.token}`,
        },
      }).then(() => {
        displayFlashMessage('Submission Added Successfully', 'success');
        setSelectedConsultantId("");
        setRate("");
        setClient("");
        SetSelectedEmployer("");
        setSelectedConsultantId("");
        setEmail("");
        setPhoneNumber("");
        setTimeout(() => {
          props.onHide();
        }, 1000);
      }).catch((error) => {
        displayFlashMessage(error.response.data.result,'error');
        setTimeout(() => {
          props.onHide();
        }, 6000);
      });
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal "
    >
      <Modal.Body style={{ width: "800px" }} className="bg-neutral-900 text-white">
        <div class="container mt-3 ">
          <div class="d-flex row justify-content-around">
            <div class="col-md-4 col-xs-12">
              <div className="d-flex flex-column">
                <div className="mt-3">
                  <label htmlFor="consultantSelect" className="form-label">
                    Consultant
                  </label>
                  <select
                    className="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    aria-label="Default select example"
                    id="consultantSelect"
                    onChange={handleConsultantChange}
                  >
                    <option value="" selected>
                      Select a consultant
                    </option>
                    {consultantList.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label for="exampleFormControlSelect" class="form-label py-2">
                    Vendor Contact:
                  </label>
                  <div className="d-flex gap-4">
                    <Select
                      className="vendor_select"
                      onChange={handleVendorChange}
                      options={vendors}
                      isSearchable={true}
                      placeholder="Search a Vendor"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: '#171111',
                          color: 'white',
                          margin: 0,
                          padding: 0,
                          border: '1px solid #171111',
                        }),
                        option: (provided) => ({
                          ...provided,
                          backgroundColor: '#171111',
                          color: 'white',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: 'white',
                        }),
                      }}
                    />
                    <Button
                      onClick={() => setModalShow(true)}
                      className="ButtonBackground1 flex gap-1 px-2 py-2.5 text-white rounded-xl bg-indigo-950"
                      style={{
                        fontSize: "12px",
                        height: "35px",
                        padding: "0",
                        right: "445px",
                        position: "absolute",
                      }}
                      disabled={!jsonData?.result.roles.some(role => ["admin", "leads", "superadmin", "marketer"].includes(role))}
                    >
                      <FontAwesomeIcon style={{ marginTop: "3px" }} icon={faPlus} /> &nbsp; New Vendor
                    </Button>
                    <AddVendorModal
                      company_id={props.lead.company_id}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Rate
                  </label>
                  <input
                    type="number"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    onChange={handleRateChange}
                  />
                </div>
                <div className="mt-3">
                  <label for="exampleFormControlSelect" class="form-label ">
                    Client
                  </label>
                  <input
                    type="text"
                    class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    id="exampleFormControlInput1"
                    onChange={handleClientChange}
                  />
                </div>
                <div className="mt-3 felx-row">
                  <label for="exampleFormControlSelect" class="form-label ">
                    DL/VISA/SSN Shared
                  </label>
                  <input type="checkbox" name="isShared" id="isShared"
                    ref={isDocumentShared}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 Section">
              {showNextSelect && (
                <div
                  className="mt-3"
                  id="nextSelectContainer"
                  style={{ display: "block" }}
                >
                  <label htmlFor="nextSelect" className="form-label">
                    Select Consultant Profile
                  </label>
                  <select
                    className="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                    aria-label="Default select example"
                    id="nextSelect"
                    onChange={handleConsultantProfileChange}
                  >
                    <option value="" selected>
                      Select a Consultant Profile
                    </option>
                    {consultantProfileList.map((item) => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                  </select>
                </div>
              )}
              <div>
                <label for="exampleFormControlSelect" class="form-label ">
                  Marketing Emails:
                </label>
                <select
                  class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  aria-label="Marketing Emails select"
                  id="exampleFormControlSelect"
                  value={email}
                  onChange={handleEmailChange}
                >
                  <option value="" selected>
                    Select Marketing Email
                  </option>
                  {marketingEmails.map((email, index) => (
                    <option key={index} value={email}>
                      {email}
                    </option>
                  ))}
                </select>
                {emailError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {emailError}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Marketing Phone Number:
                </label>
                <select
                  class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  aria-label="Marketing Phone select"
                  id="exampleFormControlSelectPhone"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                >
                  <option value="" selected>
                    Select Marketing Phone
                  </option>
                  {marketingPhones.map((phone, index) => (
                    <option key={index} value={phone}>
                      {phone}
                    </option>
                  ))}
                </select>
                {phoneError && (
                  <div
                    style={{ color: "red", fontSize: "14px" }}
                    className="error-message"
                  >
                    {phoneError}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Employer
                </label>
                <select
                  class="form-select bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  aria-label="Default select example"
                  onChange={handleEmployerChange}
                >
                  <option value="" selected>
                    Select Employer
                  </option>
                  {employer.map((item, i) => (
                    <option key={i} value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Documents Shared
                </label>
                <input
                  type="text"
                  class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  id="exampleFormControlInput1"
                  onChange={handleDocsChange}
                />
              </div>
              <div className="mt-3">
                <label for="exampleFormControlSelect" class="form-label ">
                  Comments
                </label>
                <input
                  type="text"
                  class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white"
                  id="exampleFormControlInput1"
                  onChange={handleCommentssChange}
                />
              </div>
            </div>
            <div class="mt-3" style={{ width: "87%" }}>
              <label for="formFile" class="form-label">
                <span style={{ color: 'red' }}>*</span> Attachments:
              </label>
              <input class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" type="file" id="formFile" accept=".pdf, .png, .jpeg, .jpg, .doc, .docx" onChange={handleFileChange} required />
              <p className="mt-3">
                Only PDF, PNG, JPEG and DOCS files are supported
              </p>
            </div>
            <div class="mt-3" style={{ width: "87%" }}>
              <label for="formFile" class="form-label">
                <span style={{ color: 'red' }}>*</span>Proof of Submission:
              </label>
              <input class="form-control bg-neutral-900 text-white focus:bg-neutral-900 focus:text-white" type="file" id="formFile" accept=".pdf, .png, .jpeg, .jpg, .doc, .docx" onChange={handleProofOfSubmissionChange} required />
              <p className="mt-3">
                Only PDF, PNG, JPEG and DOCS files are supported
              </p>
            </div>
            {flashMessage && (
              <FlashMessage message={flashMessage} type={flashMessageType} />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center bg-neutral-900 text-white">
        <Button
          onClick={handleSubmit}
          style={{ background: "#4681f4" }}
          disabled={
            !props.lead.company_id || !selectedVendorId || !props.lead.id
            || !selectedEmployer || !selectedConsultantProfileId
            || !email || !phoneNumber || !file
          }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
